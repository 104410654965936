import React from "react";
import "./styles.scss";


const honeyList = [
  {
    item: "Offshore Onshore / Nearshore On- Premise"
  },
  {
    item: "Experienced and High Performing Software Engineering Team"
  },
  {
    item: "Active Clients include Small to Fortune 500"
  },
  {
    item: "Expertise in Various Industries"
  },
  {
    item: "Countless Delivered Projects"
  },
  {
    item: "Agile Management Style"
  },
  {
    item: "Digital Transformation services"
  },
  {
    item: "Covering Multiple Timezones"
  },
];
const HoneyComb = () => {
  return (
    <>
      <div className="show-list">
        <br />
        <br />
        <br />
      </div>
      {honeyList.map(({ item }, idx) => {
        return (

          <div className="show-list">
            <ul>
              <li>{item}</li>
            </ul>
          </div>
        )
      })}
      <br />
      <br />


      <div className="hide-honey  move-left">
        <ul className="hex-grid__list hex-text">
          <li className="hex-grid__item hex-grid__item--light hex-grid__item--blank"></li>
          <li className="hex-grid__item hex-grid__item--blank"></li>

          <li className="hex-grid__item hex-grid__item--light hex-grid__item--lightblue">
            <div className="ou2 isBlueBack "></div>
            <div className="in2 isBlueBack "></div>
            <div className="hex-grid__content ">
              Offshore Onshore / Nearshore On- Premise
            </div>
          </li>

          <li className="hex-grid__item hex-grid__item--blank"></li>
          <li className="hex-grid__item hex-grid__item--light hex-grid__item--lightblue">
            <div className="ou2 isBlueBack "></div>
            <div className="in2 isBlueBack"></div>
            <p className="hex-grid__content">
              Experienced and High Performing Software Engineering Team
            </p>
          </li>
          <li className="hex-grid__item hex-grid__item--blank"></li>

          <li className="hex-grid__item hex-grid__item--light hex-grid__item--gray">
            <div className="ou2 isGrayBack "></div>
            <div className="in2 isGrayBack"></div>
            <p className="hex-grid__content">
              Active Clients include Small to Fortune 500
            </p>
          </li>

          <li className="hex-grid__item hex-grid__item--light hex-grid__item--bgworld"></li>
          <li className="hex-grid__item hex-grid__item--light hex-grid__item--darkblue">
            <div className="ou2 isDarkBack "></div>
            <div className="in2 isDarkBack"></div>
            <p className="hex-grid__content">Expertise in Various Industries</p>
          </li>

          <li className="hex-grid__item hex-grid__item--light hex-grid__item--bgworld"></li>

          <li className="hex-grid__item hex-grid__item--light hex-grid__item--blank"></li>

          <li className="hex-grid__item hex-grid__item--light hex-grid__item--darkblue">
            <div className="ou2 isDarkBack "></div>
            <div className="in2 isDarkBack"></div>
            <p className="hex-grid__content">Countless Delivered Projects</p>
          </li>

          <li className="hex-grid__item hex-grid__item--blank"></li>

          <li className="hex-grid__item hex-grid__item--light hex-grid__item--lightblue">
            <div className="ou2 isBlueBack "></div>
            <div className="in2 isBlueBack"></div>
            <p className="hex-grid__content">Agile Management Style</p>
          </li>
          {/* <li className="hex-grid__item hex-grid__item--light">
          <div className="in2 isBlueBack"></div>
          <p className="hex-grid__content">Agile 
Management
 Style</p>
        </li> */}

          <li className="hex-grid__item hex-grid__item--light hex-grid__item--gray">
            <div className="ou2 isGrayBack"></div>
            <div className="in2 isGrayBack"></div>
            <p className="hex-grid__content">Digital Transformation services</p>
          </li>

          {/* <li className="hex-grid__item hex-grid__item--light hex-grid__item--darkblue">
          <div className="in2 isDarkBack"></div>
          <p className="hex-grid__content">7</p>
        </li> */}

          <li className="hex-grid__item hex-grid__item--blank"></li>
          <li className="hex-grid__item hex-grid__item--blank"></li>

          {/* 
        <li className="hex-grid__item move-top">
          <p className="hex-grid__content ">6</p>
        </li> */}

          <li className="hex-grid__item hex-grid__item--light hex-grid__item--gray move-top ">
            <div className="ou2 isGrayBack "></div>
            <div className="in2 isGrayBack "></div>
            <p className="hex-grid__content">Covering Multiple Timezones</p>
          </li>
        </ul>
      </div>
    </>
  );
};
export default HoneyComb;
