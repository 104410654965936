import React, { Component } from "react";
import "react-animated-slider/build/horizontal.css";
import "react-tabs/style/react-tabs.css";
import "../styles/fontstyle.css";
import "../styles/styles.css";
import "../styles/slidernews.css";
import "../styles/styles.css";
import scrollToComponent from "react-scroll-to-component";



const language1 = ["Net MVC", "C#", "Java", "VB", "PHP", "NodeJS", "HTML5"]

const language2 = ["CSS", "Bootstrap", "Python", "JQuery", "AngularJS", "WordPress", "Joomla"]


export default class Modal1 extends Component {
    render() {
        return (


            <div
                className="modal fade bd-example-modal-lg"
                id="modal1"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="myLargeModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-sm-12 blueboxR">
                                    <div className="sec3-inner-row-light">
                                        <div className="head">
                                            <div className="white-curve-bg">
                                                <img
                                                    className="icon-small"
                                                    alt="icon"
                                                    src="../../assets/sr1.png"
                                                ></img>
                                            </div>
                                            <div className="sec3-head1">Web Development</div>
                                        </div>
                                        <div className="sub-head" >
                                            <div className="row">
                                                <div className="col-1 col-md-1">
                                                </div>
                                                <div className="col-5 col-md-4">
                                                    {language1.map((language) => {
                                                        return (
                                                            <div className="row">
                                                                <img
                                                                    className="tick"
                                                                    width="20px"
                                                                    height="20px"
                                                                    alt="tick"
                                                                    src="../../assets/tick2.png"
                                                                />
                                                                <span className="lang" >{language}</span>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                                <div className="col-5 col-md-3">
                                                    {language2.map((language) => {
                                                        return (
                                                            <div className="row">
                                                                <img
                                                                    className="tick"
                                                                    width="20px"
                                                                    height="20px"
                                                                    alt="tick"
                                                                    src="../../assets/tick2.png"
                                                                />
                                                                <span className="lang" >{language}</span>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                                <div className="col col-md-12 side">
                                                    <div className="row top-space dir">
                                                        <button
                                                            href="#"
                                                            type="button"
                                                            data-dismiss="modal"
                                                            aria-hidden="true"
                                                            aria-label="Close"
                                                            className="modal-button"
                                                            onClick={() =>
                                                                scrollToComponent(this.Others, {
                                                                    offset: -10,
                                                                    align: "top",
                                                                    duration: 500,
                                                                    ease: "inExpo",
                                                                })
                                                            }
                                                        >
                                                            <span>OUR PRICE  &nbsp;</span>
                                                        </button>
                                                        &nbsp;
                                                        <button
                                                            className="modal-button"
                                                            href="#"
                                                            type="button"
                                                            data-dismiss="modal"
                                                            aria-hidden="true"
                                                            aria-label="Close"
                                                            onClick={() =>
                                                                scrollToComponent(this.ContactUs, {
                                                                    offset: -10,
                                                                    align: "top",
                                                                    duration: 500,
                                                                    ease: "inExpo",
                                                                })
                                                            }
                                                        >
                                                            <span>REQUEST DEMO</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <br />
                                            <br />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
};
