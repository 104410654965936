import React, { Component } from "react";
import "react-animated-slider/build/horizontal.css";
import "react-tabs/style/react-tabs.css";
import "../styles/fontstyle.css";
import "../styles/styles.css";
import "../styles/slidernews.css";
import "../styles/styles.css";


export default class Paysection extends Component {
  render() {
    return (
      <div className="container-fluid">
        <br />
        <br />
        <br />
        <div className="pay-head">PAY AS YOU GROW</div>
        <br />
        <div className="container">
          <div className="pay-text">
            <div>
              As your company grows, YooFoo has the technical capabilities
              to provide you with top of the line services.
              As a long term partner with us,
              we have the
              additional savings described below. Asking questions before
              providing answers.
            </div>
            <br />
            <div className="pay-blue">
              Monthly Service Packages
              <br />
              Discount as much as
            </div>
            <br />
            <br />
          </div>
          <div className="row  " align="left">
            <div className="col-12 col-md-3 pay-space">
              {/* <div className="row pay-space "> */}
              <div className="row ">
                <div className="col-2 col-md-2 col-sm-2  ">
                  <img
                    src="../assets/2011.png"
                    alt="icon"
                    class="rounded-circle blue-border "
                  ></img>
                </div>
                <div className="col-8 col-md-10 col-sm-8 ">
                  <span className="plan-text">
                    Basic <br /> &nbsp;&nbsp;up to{" "}
                    <span className="plan-percent">20%  <br /></span>{" "}
                  </span>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-3 pay-space">
              <div className="row">
                <div className="col-2 col-md-2 col-sm-2  ">
                  <img
                    src="../assets/301.png"
                    alt="icon"
                    class="rounded-circle blue-border2 "
                  ></img>
                </div>
                <div className="col-8 col-md-10 col-sm-8">
                  <span className="plan-text">
                    Standard <br /> &nbsp;&nbsp;up to{" "}
                    <span className="plan-percent">30%</span>{" "}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-3 pay-space">
              <div className="row">
                <div className="col-2 col-md-2 col-sm-2  ">
                  <img
                    src="../assets/40.png"
                    alt="icon"
                    class="rounded-circle blue-border "
                  ></img>
                </div>
                <div className="col-8 col-md-10 col-sm-8">
                  <span className="plan-text">
                    professional <br /> &nbsp;&nbsp;up to{" "}
                    <span className="plan-percent">40%</span>{" "}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-3 pay-space">
              <div className="row">
                <div className="col-2 col-md-2 col-sm-2  ">
                  <img
                    src="../assets/501.png"
                    alt="icon"
                    class="rounded-circle blue-border2 "
                  ></img>
                </div>
                <div className="col-8 col-md-10 col-sm-8">
                  <span className="plan-text">
                    ultimate <br /> &nbsp;&nbsp;up to{" "}
                    <span className="plan-percent">50%</span>{" "}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    );
  };
};
