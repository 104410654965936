import React, { useEffect } from 'react';
// import Homescreen from '../src/components/common/home'
import Routes from '../src/components/routes/routes'
import './App.css';
import ReactGA from 'react-ga';

function App() {

  useEffect(() => {
    ReactGA.initialize('UA-171815604-2');
    // To Report Page View 
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [])

  useEffect(() => {
    console.log(window.location.About)
  })

  const script = document.createElement("script");
  script.src = "//js-na1.hs-scripts.com/19943112.js";
  script.async = true;
  document.body.appendChild(script);

  return (
    <div className="App">
      <Routes />
    </div>
  );
}

export default App;
