import React, { Component } from "react";
import { Route, HashRouter } from "react-router-dom";
import Homescreen from "../screens/home-screen";

class Routes extends Component {
  render() {
    return (
      <HashRouter>

        <Route exact path="/" component={Homescreen} />

      </HashRouter>
    );
  }
}
export default Routes;
