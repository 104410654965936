import React from "react";
import "react-animated-slider/build/horizontal.css";
import "react-tabs/style/react-tabs.css";
import Slider from "react-slick";
import "./slick.css";
import "./slick-theme.css";
import "./styles.css";




function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        position: "absolute",
        right: "2%",
        background: "white",
        borderRadius: "50%",
      }}
      onClick={onClick}
    />
  );
}
function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        position: "absolute",
        left: "2%",
        background: "white",
        borderRadius: "50%",
      }}
      onClick={onClick}
    />
  );
}
const Bank = () => {
  const settings = {
    dots: true,
    autoplay: true,
    autoplaySpeed: 6000,
    cssEase: "linear",
    arrows: true,
    infinite: true,
    speed: 2000,
    slidesToShow: 2,
    slidesToScroll: 2,
    swipeToSlide: true,
    responsive: [{

      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true
      }

    }, {

      breakpoint: 850,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true
      }

    },
      // {

      //   breakpoint: 550,
      //   settings: {
      //     slidesToShow: 1,
      //     slidesToScroll: 1,
      //     dots: true,
      //     infinite: true
      //   }

      // },
      // {

      //   breakpoint: 300,
      //   settings: "unslick" // destroys slick

      // }
    ],
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    appendDots: (dots) => (
      <ul style={{ margin: "0px", color: "red" }}> {dots} </ul>
    ),
  };

  const firstSliderSettings = {
    autoplay: true,
    autoplaySpeed: 6000,
    cssEase: "linear",
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  const firstSliderSettingsMobile = {
    autoplay: true,
    autoplaySpeed: 12000,
    cssEase: "linear",
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,

  };


  return (
    <div>
      <div className="serviceContainer1">
        <div className="outer-circle-three">
          <div className="inner-circle">
            <img className="small-icon-four" alt="Industry" src="../../assets/fourthicon.png" />
          </div>
        </div>
        <div className="industry-header">INDUSTRY EXPERT </div>
      </div>
      <div className="mobile-speed">
        <Slider
          {...firstSliderSettingsMobile}>
          <div>
            <div className="bank">BANKS, FINANCIAL SERVICES AND INSURANCE</div><br />
          </div>
          <div>
            <div className="life">life sciences</div>
            <div className="bank">BIOTECH, DIAGNOSTICS AND WELLNESS</div><br />
          </div>
        </Slider>
      </div>
      <div className="desktop-speed">
        <Slider
          {...firstSliderSettings}>
          <div>
            <div className="bank">BANKS, FINANCIAL SERVICES AND INSURANCE</div><br />
          </div>
          <div>
            <div className="life">life sciences</div>
            <div className="bank">BIOTECH, DIAGNOSTICS AND WELLNESS</div><br />
          </div>
        </Slider>
      </div>

      <Slider
        {...settings}>
        <div>

          <img
            className="industry-big"
            src="../assets/bank1.png"
            alt="Chalkcouture"
          />
        </div>
        <div>

          <img
            className="industry-big"
            src="../assets/bank2.png"
            alt="Unicity"
          ></img>
        </div>
        <div>
          <img
            className="industry-big"
            src="../assets/bank3.png"
            alt="Q-Sciences"
          ></img>
        </div>
        <div>
          <img
            className="industry-big"
            src="../assets/bank4.png"
            alt="Wink"
          ></img>
        </div>

      </Slider>
    </div>
  );

  // <div className="container-fluid">
  // <div className="serviceContainer">
  //   <div className="ourclient_hdr">Industry Expertise</div>

  //   <div
  //   class="carousel slide carousel-multi-item serviceContainer"
  //   data-ride="carousel"
  // >
  // <div class="controls-top">
  //   <a class="btn-floating" href="#multi-item-example" data-slide="prev"><i class="fa fa-chevron-left"></i></a>
  //   <a class="btn-floating" href="#multi-item-example" data-slide="next"><i class="fa fa-chevron-right"></i></a>
  // </div>

  //   <div class="carousel-inner" role="listbox">
  //     <div class="carousel-item active">

  //   <div className="container">
  //   <div className="bank">BANKS, FINANCIAL SERVICES AND INSURANCE</div>
  //   <div className="row">
  //     <div className="col col-md-6 ">
  //       <img className="bold"  src="../assets/bank1.png"></img>

  //     </div>
  //     <div className="col col-md-6">
  //     <img className="bold" src="../assets/bank2.png"></img>
  //     </div>
  //   </div>
  // </div>
  //     </div>
  //     <div class="carousel-item">
  //   <div className="life">life sciences</div>
  //   <div className="bank">BIOTECH, DIAGNOSTICS AND WELLNESS</div>
  //   <div className="container">
  //     <div className="row">
  //       <div className="col col-md-6 ">
  //         <img className="bold" src="../assets/bank3.png"></img>
  //       </div>
  //       <div className="col col-md-6">
  //         <img className="bold" src="../assets/bank4.png"></img>
  //       </div>
  //     </div>
  //   </div>
  //     </div>
  //   </div>

  //   <ol class="carousel-indicators">
  //   <li data-target="#multi-item-example" data-slide-to="0" class="active"></li>
  //   <li data-target="#multi-item-example" data-slide-to="1"></li>
  //   <li data-target="#multi-item-example" data-slide-to="2"></li>
  // </ol>
  // </div>

  /* <div className="container">
      <div className="row">
        <div className="col col-md-6 ">
          <img className="bold"  src="../assets/bank1.png"></img>
          
        </div>
        <div className="col col-md-6">
        <img className="bold" src="../assets/bank2.png"></img>
        </div>
      </div>
    </div> */
  /* <p className="life">life sciences</p>
      <p className="bank">BIOTECH, DIAGNOSTICS AND WELLNESS</p>
      <div className="container">
        <div className="row">
          <div className="col col-md-6 ">
            <img className="bold" src="../assets/bank3.png"></img>
          </div>
          <div className="col col-md-6">
            <img className="bold" src="../assets/bank4.png"></img>
          </div>
        </div>
      </div> */
  //     </div>
  //   </div>

  //   );
  // };
};

export default Bank;
