import React, { Component } from "react";
import "react-animated-slider/build/horizontal.css";
import "react-tabs/style/react-tabs.css";
import "../styles/fontstyle.css";
import "../styles/styles.css";
import "../styles/slidernews.css";
import "../styles/styles.css";

export default class Ourservices extends Component {
  render() {
    return (
      <div className="col ">
        <div>
          <div className="col ourServices">OUR Services</div>
          <div className="col subServices">
            Scale up development and complete that new
            initiative without a long-term resource commitment.
          </div>

          <div className="container-fluid">
            <div className="outer-circle-two">
              <div className="inner-circle">
                <img
                  className="small-icon-two"
                  alt="icon"
                  src="../../assets/secondicon.png"
                />
              </div>
            </div>
            <div className="row sec3-row">
              <div className="col-12 col-md-4 sec3-col-odd">
                <div className="sec3-inner-row-light text-left">
                  <img
                    className="main-icon"
                    src="../../assets/src1.png"
                    alt="icon"
                  />
                  <div
                    style={{
                      display: "inline-block",
                    }}
                  >
                    <div className="service1">
                      Distributed Agile
                      <br /> Augumentation
                    </div>
                  </div>
                </div>

                <div className="sec3-inner-row-light">
                  <div className="head">
                    <div className="white-curve-bg">
                      <img
                        className="icon-small"
                        alt="icon"
                        src="../../assets/sr1.png"
                      ></img>
                    </div>
                    <div className="sec3-head1">Web Development</div>
                  </div>

                  <div className="sub-head" >
                    A business's and enterprise's website presents your business
                    scope in detail to the viewers and promotes your objectives
                    with each click. We have a passion to create and build
                    websites as per your business objectives and transform your
                    business needs to a form a website. We will ask your needs
                    to get the best web friendly development services with the
                    latest trends of the market with our different range of
                    solutions.
                    <img
                      data-toggle="modal"
                      data-target="#modal1"
                      // data-target="#modal-services"
                      className="exit-icon"
                      src="../../assets/exit.png"
                      alt="icon"
                    />
                  </div>
                </div>
                <div className="sec3-inner-row-light">
                  <div className="head">
                    <img
                      className="icon-small"
                      alt="icon"
                      src="../../assets/sr11.png"
                    ></img>
                    <div className="sec3-head2">Mobile Development</div>
                  </div>

                  <div className="sub-head">
                    For your app to succeed, you need a smart business strategy,
                    a killer UI/UX design, and a talented mobile development
                    team. Good news - you've come to the right source. We don't
                    settle for okay, we shoot for outstanding. That's where we
                    can take you - if you’re ready. Innovative mobile business
                    strategies, beautiful user experience designs, and powerful
                    agile app development.
                    <br />
                    <br />
                    {/* <img className="exit-icon"
                      alt="icon"
                      src="../../assets/exit.png" /> */}
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-4 sec3-col-even">
                <div className="sec3-inner-row-dark text-left">
                  <img
                    className="main-icon"
                    src="../../assets/src2.png"
                    alt="icon" />
                  <div
                    style={{
                      display: "inline-block",
                    }}
                  >
                    <div className="service2">
                      Technology
                      <br /> Project Consulting
                    </div>
                  </div>
                </div>

                <div className="sec3-inner-row-dark">
                  <div className="head">
                    <div className="white-curve-bg">
                      <img
                        className="icon-small"
                        alt="icon"
                        src="../../assets/sr2.png"
                      ></img>
                    </div>
                    <div className="sec3-head3">
                      Software Architecting & Engineering
                    </div>
                  </div>

                  <div className="sub-head-white">
                    Most companies have experienced the frustration two or more
                    software programs that are unable to share critical
                    information. Lack of software interoperability of the
                    biggest problems in business. YooFoo has extensive
                    experience in developing Enterprise Resource Planning (ERP)
                    systems: systems that enable multiple, independent, software
                    applications to communicate with each other throughout the
                    organization. All of our software interfaces are created to
                    meet or exceed industry standards.
                    {/* <img
                      className="exit-icon"
                      src="../../assets/exit.png"
                      alt="icon"
                    /> */}
                  </div>
                </div>
                <div className="sec3-inner-row-dark">
                  <div className="head">
                    <div className="white-curve-bg">
                      <img
                        className="icon-small"
                        alt="icon"
                        src="../../assets/sr22.png"
                      ></img>
                    </div>
                    <div className="sec3-head3">Data Services</div>
                  </div>

                  <div className="sub-head-white">
                    Most companies have experienced the frustration of trying to
                    merge data from more than one system, or data source, with
                    disappointing results. These ‘silos’ of information create
                    inefficiencies within an organization that result in
                    cumbersome work-around and hours of wasted time. In fact,
                    lack of software interoperability is one of the biggest
                    problems reported by businesses and government organizations
                    throughout the world today.
                    <br />
                    <br />
                    {/* <img 
                    className="exit-icon"
                     alt="icon"
                     src="../../assets/exit.png" /> */}
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4 sec3-col-odd">
                <div className="sec3-inner-row-light text-left">
                  <img
                    className="main-icon"
                    src="../../assets/src3.png"
                    alt="icon"
                  />
                  <div
                    style={{
                      display: "inline-block",
                    }}
                  >
                    <div className="service1">
                      digital
                      <br /> transformation strategies
                    </div>
                  </div>
                </div>
                <div className="sec3-inner-row-light">
                  <div className="head">
                    <div className="white-curve-bg">
                      <img
                        className="icon-small"
                        alt="icon"
                        src="../../assets/sr3.png"
                      ></img>
                    </div>
                    <div className="sec3-head1">Design & Creatives</div>
                  </div>

                  <div className="sub-head">
                    For those who have capacity of web page design, the essence
                    of web designing goes never taken a dive into the beyond
                    just embedding attractive layouts with the development code.
                    We have the capabilities and creativity to make your vision,
                    into your dream web design.
                    <br />
                    <br />
                    <br />
                    <br />
                    {/* <img
                     className="exit-icon"
                     alt="icon"
                      src="../../assets/exit.png" /> */}
                  </div>
                </div>
                <div className="sec3-inner-row-light">
                  <div className="head">
                    <img
                      className="icon-small"
                      src="../../assets/sr33.png"
                      alt="icon"
                    ></img>
                    <div className="sec3-head1">Testing & Quality Analysis</div>
                  </div>

                  <div className="sub-head">
                    Software and IT organizations wanting to ensure high quality
                    releases, need to incorporate an effective quality assurance
                    and testing program in their operations.
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    {/* <img 
                    className="exit-icon" 
                    alt="icon"
                    src="../../assets/exit.png" /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Modal Start */}
        <div
          className="modal fade bd-example-modal-lg"
          id="modal-services"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Services Testin
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-sm-4 blueboxL popuplist">
                    <ul>
                      <li>
                        <i
                          className="fa fa-check okicon"
                          aria-hidden="true"
                        ></i>
                        .Net MVC
                      </li>
                      <li>
                        <i
                          className="fa fa-check okicon"
                          aria-hidden="true"
                        ></i>
                        C#
                      </li>
                      <li>
                        <i
                          className="fa fa-check okicon"
                          aria-hidden="true"
                        ></i>
                        Java
                      </li>
                      <li>
                        <i
                          className="fa fa-check okicon"
                          aria-hidden="true"
                        ></i>
                        VB
                      </li>
                      <li>
                        <i
                          className="fa fa-check okicon"
                          aria-hidden="true"
                        ></i>
                        PHP
                      </li>
                      <li>
                        <i
                          className="fa fa-check okicon"
                          aria-hidden="true"
                        ></i>
                        Node.JS
                      </li>
                      <li>
                        <i
                          className="fa fa-check okicon"
                          aria-hidden="true"
                        ></i>
                        HTML5
                      </li>
                      <li>
                        <i
                          className="fa fa-check okicon"
                          aria-hidden="true"
                        ></i>
                        CSS
                      </li>
                      <li>
                        <i
                          className="fa fa-check okicon"
                          aria-hidden="true"
                        ></i>
                        Bootstrap
                      </li>
                      <li>
                        <i
                          className="fa fa-check okicon"
                          aria-hidden="true"
                        ></i>
                        Python
                      </li>
                      <li>
                        <i
                          className="fa fa-check okicon"
                          aria-hidden="true"
                        ></i>
                        JQuery
                      </li>
                      <li>
                        <i
                          className="fa fa-check okicon"
                          aria-hidden="true"
                        ></i>
                        AngularJS
                      </li>
                      <li>
                        <i
                          className="fa fa-check okicon"
                          aria-hidden="true"
                        ></i>
                        WordPress
                      </li>
                      <li>
                        <i
                          className="fa fa-check okicon"
                          aria-hidden="true"
                        ></i>
                        Joomla
                      </li>
                    </ul>
                  </div>
                  <div className="col-sm-8 blueboxR">
                    <div className="popuppara">
                      A business's and enterprise's website presents your
                      business scope in detail to the viewers and promotes your
                      objectives with each click. We have a passion to create
                      and build websites as per your business objectives and
                      transform your business needs to a form a website. We will
                      ask your needs to get the best web friendly development
                      services with the latest trends of the market with our
                      different range of solutions.
                    </div>
                    <div className="popupimg">
                      <img
                        src="../../assets/webdev.jpg"
                        className="col-sm-6"
                        alt="Web Development"
                      ></img>
                    </div>
                    <div className="row btntmng">
                      <div className="col-md-1"></div>
                      <div className="col-md-3"></div>
                      <div className="col-md-5">
                        <button
                          href="#"
                          type="button"
                          data-dismiss="modal"
                          aria-hidden="true"
                          aria-label="Close"
                          className="btn btn-primary btn-block btntopm5"
                        // onClick={() =>
                        //   scrollToComponent(this.ContactUs, {
                        //     offset: -10,
                        //     align: "top",
                        //     duration: 500,
                        //     ease: "inExpo",
                        //   })
                        // }
                        >
                          <span>Request Quote</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
