import React from "react";
import "./styles.css";
const Section2 = () => {
  return (
    <div className="container-fluid section-2-container">
      <div className="outer-circle">
        <div className="inner-circle">
          <img className="small-icon" alt="icon" src="../../assets/firsticon.png" />
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-12 col-lg-6">
          <div className="circle-container">
            <div className="circle circleMain">
              <div className="circle innerCircle">
                <div className="build1Img" />
                <p className="cHead">What we do</p>
                <p className="cSubHead">
                  We build to your business <br />
                  needs, asking questions <br />
                  before providing answers.
                </p>
              </div>
            </div>

            <div className="circle c2 sc1 shadow ">
              <div className="circle-mid circle-mid-1"></div>
              <div className="sc1-connector"></div>
            </div>
            <div className="circle c2 sc2 shadow">
              <div className="circle-mid circle-mid-2"></div>
              <div className="sc2-connector"></div>
            </div>
            <div className="circle c2 sc3 shadow circle-one">
              <div className="circle-mid circle-mid-3"></div>
              <div className="sc3-connector"></div>
            </div>
            <div className="circle c2 sc4 shadow circle-one">
              <div className="circle-mid circle-mid-4"></div>
              <div className="sc4-connector"></div>
            </div>

            <div className="circle c3 ssc1 shadow "></div>
            <div className="circle c3 ssc2 shadow"></div>
            <div className="circle c3 ssc3 shadow "></div>
            <div className="circle c3 ssc4 shadow"></div>
          </div>
        </div>

        <div className="col col-md-12 col-lg-6">

          <div className="row">
            <div className="col-4 col-md-2 ss ">
              <div className="circle c33 ssc11 shadow "></div>
            </div>
            <div className="col-8 col-md-10 col-lg-12 ">
              <div className="container circle-head1">
                <br /> <br /> <br />
                <div className="row circle-head">design</div>
                <div className="row circle-sub-head">
                  Plug into years of experience in architecture and visual design{" "}

                  in building positive experiences for businesses and end-users
                  alike.
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-4 col-md-2 ss ">
              <div className="circle c33 ssc22 shadow "></div>
            </div>
            <div className="col-8 col-md-10 col-lg-12 ">
              <div className="container circle-head2">
                <br />
                <span className="break">
                  <br />
                </span>
                <div className="row circle-head">build</div>
                <div className="row circle-sub-head">
                  Awaken software and applications, join your needs, ideas,
                  and visions with our lean and agile process.
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-4 col-md-2 ss ">
              <div className="circle c33 ssc33 shadow "></div>
            </div>
            <div className="col-8 col-md-10 col-lg-12 ">
              <div className="container circle-head3">
                <br /> <br />
                <span className="break">
                  <br />
                </span>
                <div className="row circle-head">scale</div>
                <div className="row circle-sub-head">
                  Take advantange of our scalable solutions and trusted
                  architecture to support your critical business operations.
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-4 col-md-2 ss ">
              <div className="circle c33 ssc44 shadow "></div>
            </div>
            <div className="col-8 col-md-10 col-lg-12 ">
              <div className="container circle-head4">
                <br />
                <span className="break">
                  <br />
                  {/* <br /> */}
                </span>
                <div className="row circle-head">secure</div>
                <div className="row circle-sub-head">
                  Security is a fundamental part in our development process. It is
                  always
                  our goal to provide secure solutuions that are user-friendly.
                </div>
              </div>
            </div>
          </div>



          {/* <div className="container circle-head2">
            <br /> <br /> <br />
            <div className="row circle-head">build</div>
            <div className="row circle-sub-head">
              Awaken software and applications, join your needs, ideas, <br />
              and visions with our lean and agile process.
            </div>
          </div>
          <div className="container circle-head3">
            <br /> <br /> <br />
            <div className="row circle-head">scale</div>
            <div className="row circle-sub-head">
              Take advantange of our scalable solutions and trusted <br />
              architecture to support your critical business operations.
            </div>
          </div>
          <div className="container circle-head4">
            <br /> <br /> <br />
            <div className="row circle-head">secure</div>
            <div className="row circle-sub-head">
              Security is a fundamental part in our development process. It is
              always <br />
              our goal to provide secure solutuions that are user-friendly.
            </div>
          </div> */}
        </div>
      </div>

      <div className="row">
        <div className="col">
          <div className="container last-line">
            FROM DISCOVERY TO DEPLOYMENT, REALIZE YOUR PERFECT PRODUCT WITH
            SOFTWARE DEVELOPMENT CONSULTING
          </div>
        </div>
      </div>
      <div className="footer_br2"></div>
    </div>
  );
};

export default Section2;
