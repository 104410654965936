import React from "react";
// import { className } from "postcss-selector-parser";
import Slider from "react-slick";
import "./slick.css";
import "./slick-theme.css";
import "./styles.css";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        position: "absolute",
        right: "2%",
        background: "white",
        borderRadius: "50%",
      }}
      onClick={onClick}
    />
  );
}
function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        position: "absolute",
        left: "2%",
        background: "white",
        borderRadius: "50%",
      }}
      onClick={onClick}
    />
  );
}
const Section4 = () => {
  const settings = {
    dots: true,
    autoplay: true,
    autoplaySpeed: 4000,
    cssEase: "linear",
    arrows: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 4,
    swipeToSlide: true,
    responsive: [{

      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true
      }

    }, {

      breakpoint: 770,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        dots: true
      }

    },
    {

      breakpoint: 550,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        infinite: true
      }

    },
      // {

      //   breakpoint: 300,
      //   settings: "unslick" // destroys slick

      // }
    ],

    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    appendDots: (dots) => (
      <ul style={{ margin: "0px", color: "red" }}> {dots} </ul>
    ),
  };

  return (
    <div>
      <div className="serviceContainer1">
        <div className="outer-circle-three">
          <div className="inner-circle">
            <img
              className="small-icon-three"
              alt="icon"
              src="../../assets/thirdicon.png" />
          </div>
        </div>
        <div className="ourclient_hdr">OUR CLIENTS</div>
      </div>

      <Slider {...settings}>
        <div >
          <img
            className="clientBig"
            src="../../assets/clients/newchalkcouture1.png"
            alt="Chalkcouture"
          />
        </div>
        <div >
          <img
            className="clientBig"
            src="../../assets/clients/unicity1.png"
            alt="Unicity"
          ></img>
        </div>
        <div >
          <img
            className="clientBig"
            src="../../assets/clients/q-sciences1.png"
            alt="Q-Sciences"
          ></img>
        </div>
        <div >
          <img
            className="clientBig"
            src="../../assets/clients/newwink1.png"
            alt="Wink"
          ></img>
        </div>
        <div>
          <img
            className="clientBig"
            src="../../assets/clients/solera.png"
            alt="Solera"
          ></img>
        </div>
        <div >
          <img
            className="clientBig"
            src="../../assets/clients/dentalselect.png"
            alt="Dentalselect"
          ></img>
        </div>
        <div >
          <img
            className="clientBig"
            src="../../assets/clients/zego.png"
            alt="Zego"
          ></img>
        </div>
      </Slider>
      <br /> <br /> <br /> <br /> <br />
    </div>



  );

  // return (
  //   // <div class="slider slider-nav">
  //   //   <div>
  //   //     <h3>1</h3>
  //   //   </div>
  //   //   <div>
  //   //     <h3>2</h3>
  //   //   </div>
  //   //   <div>
  //   //     <h3>3</h3>
  //   //   </div>
  //   //   <div>
  //   //     <h3>4</h3>
  //   //   </div>
  //   //   <div>
  //   //     <h3>5</h3>
  //   //   </div>
  //   // </div>

  //   <div className="container-fluid">
  //     <div className="serviceContainer">
  //       <div className="ourclient_hdr">OUR CLIENTS</div>

  //       <div
  //         id="multi-item-example"
  //         class="carousel slide carousel-multi-item serviceContainer"
  //         data-ride="carousel"
  //       >
  //         <div class="carousel-inner" role="listbox">
  //           <div class="carousel-item active">
  //             <div class="row">
  //               <div className="col-12 col-md-3 clientLogo">
  //                 <img
  //                   className="clientBig"
  //                   src="../../assets/clients/chalkcouture1.png"
  //                   alt="Chalkcouture"
  //                 ></img>
  //               </div>
  //               <div className="col-12 col-md-3  clientLogo">
  //                 <img
  //                   className="clientBig"
  //                   src="../../assets/clients/unicity1.png"
  //                   alt="Unicity"
  //                 ></img>
  //               </div>
  //               <div className="col-12 col-md-3  clientLogo">
  //                 <img
  //                   className="clientBig"
  //                   src="../../assets/clients/q-sciences1.png"
  //                   alt="Q-Sciences"
  //                 ></img>
  //               </div>
  //               <div className="col-12 col-md-3  clientLogo">
  //                 <img
  //                   className="clientBig"
  //                   src="../../assets/clients/wink1.png"
  //                   alt="Wink"
  //                 ></img>
  //               </div>
  //             </div>
  //           </div>
  //           <div class="carousel-item">
  //             <div className="row">
  //               <div className="col-12 col-md-4 clientLogo">
  //                 <img
  //                   className="clientBig"
  //                   src="../../assets/clients/solera.png"
  //                   alt="Solera"
  //                 ></img>
  //               </div>
  //               <div className="col-12 col-md-4  clientLogo">
  //                 <img
  //                   className="clientBig"
  //                   src="../../assets/clients/dentalselect.png"
  //                   alt="Dentalselect"
  //                 ></img>
  //               </div>
  //               <div className="col-12 col-md-4  clientLogo">
  //                 <img
  //                   className="clientBig"
  //                   src="../../assets/clients/zego.png"
  //                   alt="Zego"
  //                 ></img>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //         <div
  //           id="multi-item-example"
  //           class="carousel slide carousel-multi-item"
  //           data-ride="carousel"
  //         >
  //           <div class="controls-top">
  //             <a
  //               class="btn-floating"
  //               href="#multi-item-example"
  //               data-slide="prev"
  //             >
  //               <i class="fa fa-chevron-left"></i>
  //             </a>
  //             <a
  //               class="btn-floating"
  //               href="#multi-item-example"
  //               data-slide="next"
  //             >
  //               <i class="fa fa-chevron-right"></i>
  //             </a>
  //           </div>
  //         </div>
  //         <ol class="carousel-indicators">
  //           <li
  //             data-target="#multi-item-example"
  //             data-slide-to="0"
  //             class="active"
  //           ></li>
  //           <li data-target="#multi-item-example" data-slide-to="1"></li>
  //         </ol>
  //       </div>
  //     </div>
  //   </div>
  // );
};
export default Section4;
