import React, { Component } from "react";
import "../styles/styles.css";

export default class Footerscreen extends Component {
  render() {
    return (
      <div>
        <div className="footerbg">
          <div className="row contact-us">Contact us</div>
          <div className="row">
            <div className="col col-md-8">
              <div className="row">
                <div className="col-1 col-md-1">
                </div>
                <div className="col address col-md-8">
                  <img className="location-size" alt="Location" src="../assets/location.png" />
                  Address
                </div>
              </div>
              <div className="row">

                <div className="col-2 col-md-1 ">
                  <a
                    href=" https://goo.gl/maps/yVXpm3a6z55Nin546"
                    rel="noreferrer noopener"
                    target="_blank" >
                    <img className="directionIcon" alt="Directions" src="../../assets/direction.png" />
                  </a>
                </div>

                <div className="col-6 col-md-3 address-contact vertical-line">
                  United States
                  <br /> YooFoo,<br />
                  LLC 9192 South 300 West Suite 3 Sandy,<br />  Utah 84070
                </div>

                <div className="col-2 col-md-1 ">
                  <a
                    href="https://goo.gl/maps/HbGideftGZRd6Zrh9"
                    rel="noreferrer noopener"
                    target="_blank" >
                    <img className="directionIcon" alt="Directions" src="../../assets/direction.png" />
                  </a>
                </div>
                <div className="col col-md-4 address-contact2">

                  India
                  <br />
                  Yoofoo Technologies India Private Limited
                  <br />
                  Suite S7, Vatika Business Centre,
                  <br />
                  6th Floor, Omega-C Block, Divyasree Building,
                  <br />
                  Hitech City Rd, Kondapur, Hyderabad
                  <br />
                  Telangana - 500081
                  <br />
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="col address ">
                <img className="phone-size"
                  alt="Call"
                  src="../assets/call.png" />
                Phone
              </div>
              <div className="col contact-number ">
                <img
                  className="address-contact address-size"
                  alt="USA Flag"
                  src="../assets/usa.png"
                />
                &nbsp; +1-800-741-8264
              </div>
              <div className="col contact-number ">
                <img
                  className="address-contact address-size"
                  alt="India Flag"
                  src="../assets/india.png"
                />
                &nbsp; 040-71055788
              </div>
            </div>
          </div>

          <div className="footer_br"></div>
          <div className="footer_br1"></div>
          <div className="col-md-12">
            <div className="footerlogo">
              {" "}
              <img
                className="img-fluid footer-logo-size"
                src="../../assets/logo-yoofoo-gray-scale.png"
                alt="Logo"
              ></img>
            </div>
            <div className="col-md-12 col-sm-12">
              <div className="footercopy">Software Developers (USA, INDIA)</div>
              <div className="footercopy">Administrative Assistant (USA)</div>
            </div> <br />

            <div className="col-md-12 col-sm-12">
              <div className="footercopy">
                YooFoo is an international company that specializes in creating
                and maintaining innovative information technology solutions.
              </div>
            </div>

            <div className="row footercopy">
              <div className="col-md-3"></div>
              <div className="col-md-6">
                &nbsp; Copyright © {new Date().getFullYear()} YooFoo All Rights
                Reserved
              </div>
              <div className="col-md-2  ">
                <a
                  href="https://www.facebook.com/pages/Yoofoo/258692607528105"
                  rel="noreferrer noopener"
                  target="_blank" >
                  <img className="bottom-social" alt="social" src="../../assets/facebook.png" />
                </a>
                <a
                  href="https://www.linkedin.com/company/yoofoo-com-llc"
                  rel="noreferrer noopener"
                  target="_blank" >
                  <img className="bottom-social" alt="social" src="../../assets/linkedin.png" />
                </a>
                <a
                  href="https://twitter.com/YooFooCom?t=wu29LijTrwGhXsIyn2XYJw&s=08"
                  rel="noreferrer noopener"
                  target="_blank" >
                  <img className="bottom-social-twitter" alt="social" src="../../assets/twitter.png" />
                </a>

              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
