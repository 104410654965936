import React from "react";
import HoneyComb from "../honeyComb";
// import ModalAbout from "../modals/modal-about";
import "./about-style.css";

const About = () => {
  return (
    /* ABOUT US Section */
    <div className="container-fluid back">
      <div className="row">
        <div className="col-12 col-md-6  ">
          <div className="container about">
            <div className="about-bar"></div>
            <div className="about-us">About US</div>
            <br />
            <br />
            <div className="about-us-text-one">
              A respected world leader
              in the provision of technology,
              software and data solutions.
              <br />
              <br />
              <br />
              <span className="name-one">Yoofoo</span> is an international
              information technology company
              that specializes in creating and maintaining innovative
              information technology, data, and software solutions for a{" "}

              wide range of needs in both private business and {" "}
              public/governmental organization environments.
              <br />
              <br />
              <br />
              <button

                className="readMoreBorder  readMoreText"
                data-toggle="modal"
                // data-target=".bd-example-modal-lg"
                data-target="#modal-about"
              >
                READ MORE ABOUT US
                <img className="button-more" alt="icon" src="../../assets/more.png" />
              </button>
            </div>
            <br />
            <br />
            <br />
            <br />

            {/* <div className="row about-bar-bottom"></div> */}
          </div>
        </div>
        <div className="col-12 col-md-6 honey-comb honey-list">
          <HoneyComb />
        </div>
      </div>

      {/* Modal Start */}
      <div
        className="modal fade bd-example-modal-lg"
        id="modal-about"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel"></h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="container about modal-left">
                <div className="row about-bar about-bar-modal"></div>
                <div className="about-us">About US</div>
                <br />
                <div className="about-us-text-two">
                  <span className="name-two">Yoofoo</span> is a minority-owned
                  company which was founded in 2010.YooFoo's primary <br />
                  aim is to use technical skills and experience to make a
                  difference in <br />
                  people and businesses.
                  <br />
                  <br />
                  <br />
                  YooFoo has its offices in the US (Headquarters) and
                  international office in <br />
                  India (International). The international office is occupied
                  by the YooFoo <br />
                  Technologies India Private Limited.YooFoo Technologies India
                  Private <br />
                  Limited was registered in September 2014. Their registered
                  address on file <br />
                  is Vatika Business Centre, 6th Floor, Omega-C Block,
                  Divyasree Building, <br />
                  Hitech City Rd, Kondapur, Hyderabad - 500081, Telangana,
                  India. Plans are <br />
                  underway to establish more international offices in
                  Thailand, and <br />
                  opportunities in other regions are currently being explored.
                  <br />
                  <br />
                  <br />
                  <br />
                </div>

                <div className="row about-bar-bottom modal-bottom-left"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Modal End */}
    </div>
  );
};
export default About;
