import React, { Component, } from "react";
import scrollToComponent from "react-scroll-to-component";
import { TinyButton as ScrollUpButton } from "react-scroll-up-button";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  NavLink,
  NavbarBrand,
} from "reactstrap";
import Slider from "react-animated-slider";
import "react-animated-slider/build/horizontal.css";
import "react-tabs/style/react-tabs.css";
import "../styles/fontstyle.css";
import "../styles/styles.css";
import "../styles/slidernews.css";
import Footerscreen from "../screens/footer";
import Section2 from "./section2";
import Section4 from "./section4/section4";
import Paysection from "./paysection";
import Ourservices from "./our-services";
import Bank from "./bank/bank";
import About from "./about/about";
import Modal1 from "./Modal1";


const content = [
  {
    title: (
      <span className="banner-heading">
        Digital <span className="banner-middle">Transformation </span> Solutions
      </span>
    ),
    description: "Through Distributed Agile Team",
    image: "../assets/ban1.png",
  },
  {
    title: (
      <span className="banner-heading">
        Software, <span className="banner-middle">and Data </span>  Solutions
      </span>
    ),
    description: "A Respected World Leader in the Provision of Technology",
    image: "../assets/ban2.png",
  },
  {
    // title: (
    //   <p className="h1_hdr">
    //     Agile <div>Project Management </div> <div> Style </div>
    //   </p>
    // ),
    // description: "Keeping Your Needs As Our Priority",

    title: (
      <span className="banner-heading">
        Digital <span className="banner-middle">Transformation </span> Solutions
      </span>
    ),
    description: "Through Distributed Agile Team",
    image: "../assets/ban1.png",
  },
  {
    // title: "Software Engineers ",
    // description: "- Hire As You Need Them",
    title: (
      <span className="banner-heading">
        Software, <span className="banner-middle">and Data </span>  Solutions
      </span>
    ),
    description: "A Respected World Leader in the Provision of Technology",
    image: "../assets/ban2.png",
  },
]

const language1 = ["Net MVC", "C#", "Java", "VB", "PHP", "NodeJS", "HTML5"]

const language2 = ["CSS", "Bootstrap", "Python", "JQuery", "AngularJS", "WordPress", "Joomla"]

export default class Homescreen extends Component {


  handleOutsideClick = (event) => {
    if (this.box && !this.box.current.contains(event.target)) {
      this.setState({
        isOpen: false,
      });
    }
  }


  componentDidMount() {
    scrollToComponent(this.Homescreen, {
      offset: 0,
      align: "top",
      duration: 500,
      ease: "inCirc",
    });

    document.addEventListener('click', this.handleOutsideClick);

  }
  constructor(props) {
    super(props);
    this.box = React.createRef();

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
      name: "",
      phone: "",
      email: "",
      message: "",
    };
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }
  sendContact() {
    console.log("sendContact", this.state.name);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        name: this.state.name,
        phone: this.state.phone,
        email: this.state.email,
        message: this.state.message,
      }),
    };
    fetch(
      "https://hak82oy2x6.execute-api.us-east-1.amazonaws.com/sendContactForm",
      requestOptions
    ).then((response) => {
      if (response.ok) {
        alert("Thanks for Reaching us! We'll get back to you!");
        this.setState({
          name: "",
          email: "",
          phone: "",
          message: "",
        });
      } else {
        response.text().then((text) => {
          alert("Error : " + text);
        });
      }
    });
  }

  render() {
    const BASE_URL = "#";
    console.log("sendContact", this.state.name);
    return (
      <>
        <div >
          <Navbar color="light" fixed="top" light expand="md">
            <div ref={this.box}>{this.props.children}
              <NavbarToggler onClick={this.toggle} />
            </div>

            <NavbarBrand href="/" id="brand" className="m-auto">
              <img
                className="img-fluid logopdng"
                src="../../assets/yoofoo_logo.png"
                alt="Yoofoo logo"

              ></img>
            </NavbarBrand>
            <Collapse isOpen={this.state.isOpen} navbar>

              <Nav className="m-auto" navbar>
                <NavItem>
                  <NavLink
                    onClick={() =>
                      scrollToComponent(this.Home, {
                        offset: -100,
                        align: "top",
                        duration: 5000,
                      })
                    }
                  >
                    HOME
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    onClick={() =>
                      scrollToComponent(this.Services, {
                        offset: -100,
                        align: "top",
                        duration: 5000,
                      })
                    }
                  >
                    SERVICES
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className="rightLogo"
                    onClick={() =>
                      scrollToComponent(this.Industry, {
                        offset: -100,
                        align: "top",
                        duration: 5000,
                      })
                    }
                  >
                    INDUSTRIES
                  </NavLink>
                </NavItem>

                {/* <div className="col-md-4 col-sm-4 logoalign" id="brand2">
                  <a href={BASE_URL}>
                    <img
                      className="img-fluid logopdng"
                      src="../../assets/yoofoo_logo.png"
                      alt="Yoofoo logo"
                    ></img>
                  </a>
                </div> */}
                <NavbarBrand href="/" className="m-auto hide-logo-mobile">
                  <a href={BASE_URL}>
                    <img
                      className="img-fluid logopdng"
                      src="../../assets/yoofoo_logo.png"
                      alt="Yoofoo logo"

                    ></img>
                  </a>
                </NavbarBrand>

                <NavItem>
                  <NavLink
                    className="leftLogo"
                    onClick={() =>
                      scrollToComponent(this.About, {
                        offset: -100,
                        align: "top",
                        duration: 5000,
                      })
                    }
                  >
                    ABOUT
                  </NavLink>
                </NavItem>
                <NavItem
                  style={{
                    minWidth: "140px",
                  }}
                >
                  <NavLink
                    onClick={() =>
                      scrollToComponent(this.ContactUs, {
                        offset: -10,
                        align: "top",
                        duration: 5000,
                        ease: "inExpo",
                      })
                    }
                  >
                    CONTACT US
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    onClick={() =>
                      scrollToComponent(this.Others, {
                        offset: -100,
                        align: "top",
                        duration: 5000,
                      })
                    }
                  >
                    OTHER
                  </NavLink>
                </NavItem>
              </Nav>
            </Collapse>
          </Navbar>

          <div
            className="col-md-12 col-sm-12 paddinglr"
            ref={(section) => {
              this.Home = section;
            }}
          >
            <Slider
              autoplay={2000}
              className="slider-wrapper">
              {content.map((item, index) => (
                <div
                  key={index}
                  className="slider-content"
                  style={{
                    background: `url('${item.image}') no-repeat center center`,
                  }}
                  autoplay={5}
                >

                  <div className="inner">
                    <h1 className="banner-title">{item.title}</h1>
                    <p className="banner-description">{item.description}</p>
                  </div>
                </div>
              ))}
            </Slider>

          </div>
          <Section2 />

          <div
            ref={(section) => {
              this.Services = section;
            }}
          ></div>
          <Ourservices />
          <div
            ref={(section) => {
              this.Others = section;
            }}
          ></div>
          <Paysection />
          <Section4 />
          <div
            ref={(section) => {
              this.About = section;
            }}
          ></div>
          <About />
          <div
            ref={(section) => {
              this.Industry = section;
            }}
          ></div>
          <Bank />
          <div>
            <ScrollUpButton
              TransitionClassName="AnyClassForTransition"
              AnimationDuration={7000}
            />
          </div>


          <div className="row">
            <div className="col">
              <hr className="blue-line"></hr>
            </div>
            <div className="col">
              <hr className="blue-line"></hr>
            </div>
          </div>

        </div>



        <div className="col-md-12 col-sm-12 ">
          <div
            className="row innerContent"
            ref={(section) => {
              this.ContactUs = section;
            }}
          >
            <div className="serviceContainer">
              <p className="bank1">Get In Touch</p>
              <div className="container">
                <div className="row">
                  <div className="col col-md-2 co-sm-1 "></div>
                  <div className="col-0 col-md-4 ">
                    <p className="form-text">NAME*</p>
                    <input
                      className="contact"
                      type="text"
                      required=""
                      aria-required="true"
                      value={this.state.name}
                      onChange={(txtNm) =>
                        this.setState({ name: txtNm.target.value })
                      }
                    />
                    <p className="form-text">PHONE NUMBER*</p>
                    <input
                      className="contact"
                      type="text"
                      aria-required="true"
                      value={this.state.phone}
                      onChange={(txtPn) =>
                        this.setState({ phone: txtPn.target.value })
                      }
                    />
                  </div>
                  <div className="col col-md-4">
                    <p className="form-text">EMAIL*</p>
                    <input
                      className="contact"
                      type="text"
                      required=""
                      aria-required="true"
                      value={this.state.email}
                      onChange={(txtEm) =>
                        this.setState({ email: txtEm.target.value })
                      }
                    />
                    <p className="form-text">SUBJECT</p>
                    <input className="contact" type="text" />
                  </div>
                </div>

                <div className="row">
                  <div className="col col-md-12  ">
                    <div className="row">
                      <div className="col-0 col-md-2"></div>
                      <div className="col-sm-12 col-md-8">
                        <p className="form-text form-text2">HOW CAN I HELP? *</p>
                        <textarea
                          className=" contact-area"
                          rows="5"
                          required=""
                          aria-required="true"
                          value={this.state.message}
                          onChange={(txtCm) =>
                            this.setState({
                              message: txtCm.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col col-md-12  ">
                    <div className="row">
                      <div className="col col-md-3"></div>
                      <div className="col-0 col-md-6">
                        <input
                          className="submit-button"
                          onClick={() => this.sendContact()}
                          value="SUBMIT CONTACT"
                          type="submit"
                        ></input>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row"></div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
        <br />

        <Footerscreen />

        <div
          className="modal fade bd-example-modal-lg"
          id="modal1"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-body">
                <div className="row">
                  <div className="col-sm-12 blueboxR">
                    <div className="sec3-inner-row-light">
                      <div className="head">
                        <div className="white-curve-bg">
                          <img
                            className="icon-small"
                            alt="icon"
                            src="../../assets/sr1.png"
                          ></img>
                        </div>
                        <div className="sec3-head1">Web Development</div>
                      </div>
                      <div className="sub-head" >
                        <div className="row">
                          <div className="col-1 col-md-1">
                          </div>
                          <div className="col-5 col-md-4">
                            {language1.map((language) => {
                              return (
                                <div className="row">
                                  <img
                                    className="tick"
                                    width="20px"
                                    height="20px"
                                    alt="tick"
                                    src="../../assets/tick2.png"
                                  />
                                  <span className="lang" >{language}</span>
                                </div>
                              )
                            })}
                          </div>
                          <div className="col-5 col-md-3">
                            {language2.map((language) => {
                              return (
                                <div className="row">
                                  <img
                                    className="tick"
                                    width="20px"
                                    height="20px"
                                    alt="tick"
                                    src="../../assets/tick2.png"
                                  />
                                  <span className="lang" >{language}</span>
                                </div>
                              )
                            })}
                          </div>
                          <div className="col col-md-12 side">
                            <div className="row top-space dir">
                              <button
                                href="#"
                                type="button"
                                data-dismiss="modal"
                                aria-hidden="true"
                                aria-label="Close"
                                className="modal-button"
                                onClick={() =>
                                  scrollToComponent(this.Others, {
                                    offset: -10,
                                    align: "top",
                                    duration: 500,
                                    ease: "inExpo",
                                  })
                                }
                              >
                                <span>OUR PRICE  &nbsp;</span>
                              </button>
                              &nbsp;
                              <button
                                className="modal-button"
                                href="#"
                                type="button"
                                data-dismiss="modal"
                                aria-hidden="true"
                                aria-label="Close"
                                onClick={() =>
                                  scrollToComponent(this.ContactUs, {
                                    offset: -10,
                                    align: "top",
                                    duration: 500,
                                    ease: "inExpo",
                                  })
                                }
                              >
                                <span>REQUEST DEMO</span>
                              </button>
                            </div>
                          </div>
                        </div>
                        <br />
                        <br />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal1 />
      </>
    );
  }
}
